import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { experiences, projects, aboutContent, links } from './content';
import { ExperienceCard, ProjectCard } from './cards';

const DynamicBackground = ({ colors }) => {
  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: -1,
      overflow: 'hidden',
    }}>
      {[...Array(20)].map((_, i) => (
        <motion.div
          key={i}
          style={{
            position: 'absolute',
            background: colors.primary,
            borderRadius: '50%',
          }}
          initial={{
            top: `${Math.random() * 100}%`,
            left: `${Math.random() * 100}%`,
            scale: Math.random() * 0.5 + 0.5,
            opacity: Math.random() * 0.3 + 0.1,
          }}
          animate={{
            top: `${Math.random() * 100}%`,
            left: `${Math.random() * 100}%`,
            scale: Math.random() * 0.5 + 0.5,
            opacity: Math.random() * 0.3 + 0.1,
          }}
          transition={{
            duration: Math.random() * 10 + 20,
            repeat: Infinity,
            repeatType: 'reverse',
          }}
        />
      ))}
    </div>
  );
};

const EnhancedPixelArt = ({ colors }) => {
  const gridSize = 20;
  const [hoveredPixel, setHoveredPixel] = useState(null);

  return (
    <motion.div
      style={{
        display: 'grid',
        gridTemplateColumns: `repeat(${gridSize}, 1fr)`,
        gap: '2px',
        width: '200px',
        height: '200px',
        margin: '0 auto 30px',
      }}
      initial={{ rotateY: 0, scale: 0.5, opacity: 0 }}
      animate={{ rotateY: 360, scale: 1, opacity: 1 }}
      transition={{ duration: 20, repeat: Infinity, ease: "linear" }}
    >
      {[...Array(gridSize * gridSize)].map((_, index) => (
        <motion.div
          key={index}
          style={{
            backgroundColor: colors.secondary,
            borderRadius: '2px',
          }}
          initial={{ scale: 1 }}
          animate={{ 
            scale: hoveredPixel === index ? 1.2 : 1,
            backgroundColor: hoveredPixel === index ? colors.primary : colors.secondary,
          }}
          transition={{ duration: 0.2 }}
          onHoverStart={() => setHoveredPixel(index)}
          onHoverEnd={() => setHoveredPixel(null)}
          whileHover={{ scale: 1.2 }}
        />
      ))}
    </motion.div>
  );
};

const ResumeApp = () => {
  const [darkMode, setDarkMode] = useState(true);
  const [activeSection, setActiveSection] = useState('about');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setIsLoading(false), 1500);
  }, []);

  const toggleDarkMode = () => setDarkMode(!darkMode);

  const colors = {
    background: '#000000',
    text: '#ffffff',
    primary: '#ff00ff', // Pink
    secondary: '#1a1a1a',
    accent: '#ff69b4', // Light pink
  };

  const containerStyle = {
    fontFamily: "'Roboto', sans-serif",
    backgroundColor: colors.background,
    color: colors.text,
    minHeight: '100vh',
    backgroundImage: 'linear-gradient(to bottom, #000000, #1a1a1a)',
    transition: 'background-color 0.5s ease, color 0.5s ease',
  };

  const headerStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 40px',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    backdropFilter: 'blur(5px)',
    zIndex: 1000,
  };

  const mainStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    padding: '80px 20px 20px',
  };

  const sectionStyle = {
    width: '100%',
    maxWidth: '800px',
    padding: '40px',
    backgroundColor: 'rgba(26, 26, 26, 0.8)',
    borderRadius: '12px',
    boxShadow: '0 4px 6px rgba(255, 255, 255, 0.1)',
  };

  const buttonStyle = {
    backgroundColor: 'transparent',
    border: `2px solid ${colors.primary}`,
    color: colors.primary,
    padding: '10px 20px',
    borderRadius: '25px',
    cursor: 'pointer',
    fontSize: '16px',
    transition: 'background-color 0.3s, color 0.3s',
  };

  const PixelArt = ({ colors }) => {
    const pixelSize = 8;
    const gridSize = 20;
    
    return (
      <motion.div
        style={{
          display: 'grid',
          gridTemplateColumns: `repeat(${gridSize}, ${pixelSize}px)`,
          gridTemplateRows: `repeat(${gridSize}, ${pixelSize}px)`,
          gap: '1px',
          marginBottom: '30px',
          justifyContent: 'center',
        }}
        initial={{ rotateY: 0, scale: 0.5, opacity: 0 }}
        animate={{ rotateY: 360, scale: 1, opacity: 1 }}
        transition={{ duration: 20, repeat: Infinity, ease: "linear" }}
      >
        {Array.from({ length: gridSize * gridSize }).map((_, index) => (
          <motion.div
            key={index}
            style={{
              width: pixelSize,
              height: pixelSize,
              backgroundColor: index % 3 === 0 ? colors.primary : 
                               index % 3 === 1 ? colors.accent : 
                               colors.secondary,
              borderRadius: '1px',
            }}
            initial={{ opacity: 0, scale: 0 }}
            animate={{ 
              opacity: [0.5, 1, 0.5],
              scale: [1, 1.2, 1],
              z: [0, 10, 0],
            }}
            transition={{ 
              duration: 4, 
              repeat: Infinity, 
              delay: index * 0.01,
              ease: "easeInOut"
            }}
          />
        ))}
      </motion.div>
    );
  };

  const sectionVariants = {
    hidden: { opacity: 0, y: 50, scale: 0.9 },
    visible: { 
      opacity: 1, 
      y: 0, 
      scale: 1,
      transition: { 
        type: "spring", 
        stiffness: 300, 
        damping: 30 
      }
    },
    exit: { opacity: 0, y: -50, scale: 0.9 },
  };

  if (isLoading) {
    return (
      <div style={{...containerStyle, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          style={{fontSize: '2rem', color: colors.primary}}
        >
          Loading...
        </motion.div>
      </div>
    );
  }

  return (
    <div style={containerStyle}>
      <DynamicBackground colors={colors} />

      <header style={headerStyle}>
        <motion.h1
          initial={{ x: -50, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
          style={{fontSize: '2.5rem', fontWeight: '700', color: colors.primary}}
        >
          MS
        </motion.h1>
        <nav>
          {['About', 'Projects', 'Work', 'Links'].map((section) => (
            <motion.button 
              key={section} 
              onClick={() => setActiveSection(section.toLowerCase())}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              style={{
                marginLeft: '20px',
                color: activeSection === section.toLowerCase() ? colors.primary : colors.text,
                background: 'none',
                border: 'none',
                cursor: 'pointer',
                fontSize: '1rem',
                fontWeight: '500',
                transition: 'color 0.3s',
              }}
            >
              {section}
            </motion.button>
          ))}
        </nav>
        <motion.button
          onClick={toggleDarkMode}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
          style={buttonStyle}
        >
          {darkMode ? '☀️' : '🌙'}
        </motion.button>
      </header>

      <main style={mainStyle}>
        <AnimatePresence mode="wait">
          {activeSection === 'about' && (
            <motion.section
            key="about"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.5 }}
            style={sectionStyle}
          >
            <motion.h2
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2, duration: 0.5 }}
              style={{fontSize: '3rem', fontWeight: '700', marginBottom: '20px', color: colors.primary}}
            >
              {aboutContent.name}
            </motion.h2>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4, duration: 0.5 }}
              style={{fontSize: '1.5rem', marginBottom: '20px', color: colors.accent}}
            >
              {aboutContent.title}
            </motion.p>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.6, duration: 0.5 }}
              style={{fontSize: '1rem', lineHeight: '1.6'}}
            >
              {aboutContent.description.split('\n').map((paragraph, index) => (
                <motion.p
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.8 + index * 0.2, duration: 0.5 }}
                  style={{marginBottom: '1rem'}}
                >
                  {paragraph}
                </motion.p>
              ))}
            </motion.div>
          </motion.section>
          )}

          {activeSection === 'projects' && (
            <motion.section
              key="projects"
              variants={sectionVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              transition={{ duration: 0.5 }}
              style={{...sectionStyle, maxWidth: '1200px'}}
            >
              <h2 style={{fontSize: '2rem', fontWeight: '700', marginBottom: '30px', color: colors.primary}}>Projects</h2>
              <div style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
                gap: '20px',
              }}>
                {projects.map((project, index) => (
                  <ProjectCard key={index} project={project} colors={colors} />
                ))}
              </div>
            </motion.section>
          )}

          {activeSection === 'work' && (
            <motion.section
              key="work"
              variants={sectionVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              transition={{ duration: 0.5 }}
              style={sectionStyle}
            >
              <h2 style={{fontSize: '2rem', fontWeight: '700', marginBottom: '30px', color: colors.primary}}>Work Experience</h2>
              {experiences.map((exp, index) => (
                <ExperienceCard key={index} experience={exp} colors={colors} />
              ))}
            </motion.section>
          )}

          {activeSection === 'links' && (
            <motion.section
              key="links"
              variants={sectionVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              transition={{ duration: 0.5 }}
              style={sectionStyle}
            >
              <h2 style={{fontSize: '2rem', fontWeight: '700', marginBottom: '30px', color: colors.primary}}>Links</h2>
              {links.map((link, index) => (
                <motion.div 
                  key={index} 
                  style={{marginBottom: '15px'}}
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: index * 0.1 }}
                >
                  <span style={{fontSize: '1rem', marginRight: '8px'}}>{link.description}</span>
                  <a 
                    href={link.url} 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    style={{
                      color: colors.primary,
                      textDecoration: 'none',
                      fontWeight: 'bold',
                      transition: 'color 0.3s',
                    }}
                  >
                    {link.title}
                  </a>
                </motion.div>
              ))}
            </motion.section>
          )}
        </AnimatePresence>
      </main>
    </div>
  );
};



export default ResumeApp;