/// placeholder for content 

// resumeData.js

export const aboutContent = {
    name: 'Matt Smart',
    title: 'Tech Founder / Smart Contract Dev / AI Experimenter/ 1/3 Actuary',
    description: `Tech founder that enjoys building in DeFi and AI. Background in data science, software dev and financial modeling.
    Currently CEO / Co-Founder of Robo Labs launching projects such as RoboVault a DeFi protocol for delta-neutral strategies, and Hikikomori Haven, an AI waifu chatbot. 
    Previously CFO / Data Scientist Okra Solar, a tech startup in the off-grid solar energy sector. 
    Academic background in Actuarial Science and Economics.
    `,
}

export const experiences = [
    {
      title: 'CEO / Co-Founder',
      company: 'Robo Labs',
      period: 'Q3 2021 - Present',
      description: 'Led team that generated over $700k in revenue from grants & software development consulting. Developed, launched, and operated the DeFi protocol RoboVault.',
      details: 'Achievements include:<br><br>' +
               '• Achieved peak AUM/TVL of $50M and over $400k in revenue generated from performance fees<br>' +
               '• Led design, development and testing of smart contracts for delta-neutral strategies and other on-chain products, ensuring rigorous security reviews<br>' +
               '• Led development of an MVP AI Companion app using in-house fine-tuned LLM, RAG, and state-of-the-art AI technology<br>' +
               '• Created quantitative models, risk analysis and simulations for delta-neutral yield opportunities using various DeFi protocols'
    },
    {
      title: 'CFO / Data Scientist',
      company: 'Okra Solar',
      period: 'Q1 2017 - Q4 2021',
      description: 'Managed financial operations and led data science initiatives for a rapidly growing tech startup in the off-grid solar energy sector.',
      details: 'Key responsibilities and achievements:<br><br>' +
               '• Liaised with investors, created fundraising materials, and completed due diligence to successfully raise over $4mil from equity, grants and debt finance<br>' +
               '• Led development of business models to secure a pipeline of $5mil in project finance for off-grid electrification, leading to >1000 households electrified<br>' +
               '• Led financial management, growing the team from 5 FTE in 2017 to over 25 FTE in 2021<br>' +
               '• Developed Spekboom - AI tool to identify rooftops from satellite imagery and run simulations to estimate the least cost of electrification mode for off-grid communities<br><br>' +
               'For more information about our innovative off-grid solutions, check out the <a href="https://www.okrasolar.com/blog" style="color: #ff00ff; text-decoration: underline;">Okra Solar blog</a>.'
    },
    {
      title: 'Data Analyst',
      company: 'Aon',
      period: 'Q1 2014 - Q4 2017',
      description: 'Consulted on data analytics projects for various Aon clients.',
      details: 'Key responsibilities and achievements:<br><br>' +
               '• Product Development, Account Management for multiple Remuneration Reports.<br>' +
               '• Custom and automated analytics on large data sets, Data integrity, Delivery of ad-hoc client projects.<br>' +
               '• Development of multiple data analytic automations used interally <br>' 
    },
  ];
  
  export const projects = [
    {
        title: 'RoboVault',
        image : '/robovault1.webp',
        description: 'DeFi protocol for Delta Neutral Strategies',
        //techStack: 'Solidity, Vyper, Python',
        details: 'DeFi protocol leveraging on delta-neutral yield strategies. ' +
                 'Key features and achievements:<br><br>' +
                 '• Achieved peak AUM/TVL of $50M<br>' +
                 '• Generated over $400k in revenue from performance fees<br>' +
                 '• Delivered consistent 10% APY on stablecoins over 18 months without token incentives<br>' +
                 '• Implemented advanced risk management and automated rebalancing mechanisms<br><br>' +
                 'Links <br>' +
                 '<a href="https://github.com/orgs/RoboVault/" style="color: #ff00ff; text-decoration: underline;">GitHub</a>.<br>' + 
                 'Articles : <a href="https://medium.com/@robovault" style="color: #ff00ff; text-decoration: underline;">Medium</a>.<br>' + 
                 'Simulations : <a href="https://github.com/RoboVault/robo-sim" style="color: #ff00ff; text-decoration: underline;">Repo</a>.'

      },
      {
        title: 'Dojima Finance',
        image : '/Dojima.webp',
        description: 'Experimental DeFi Protocol for Derivatives (not launched)',
        //techStack: 'Solidity, Vyper, Python',
        details: 'Built experimental protocol with multiple unique features ' +
                 'Key features and achievements:<br><br>' +
                 '• Winner Cronos DeFi Hackathon<br>' +
                 '• 2nd Place DSLA Hackathon<br>' +
                 'Medium article detailing mechanics : <a href="https://medium.com/@dojimabeanexchange/introducing-dojima-finance-bedcc9841d1d" style="color: #ff00ff; text-decoration: underline;">Article</a>.'
      },
    {
      title: 'Hikikomori Haven (HH)',
      description: 'AI Waifu Chatbot',
      image : '/shinji.jpeg',
      //techStack: 'Python, TypeScript',
      details: 'A fun side project built alongside <a href="https://github.com/0xh11d" style="color: #ff00ff; text-decoration: underline;">0xh11d</a>. ' +
               'Hikikomori Haven is an AI-powered chatbot that provides companionship and conversation. <br>' +
               'The project leverages state-of-the-art language models and machine learning techniques to create engaging and context-aware interactions.<br>' + 
               '<a href="hikikomorihaven.com" style="color: #ff00ff; text-decoration: underline;">Beta Link</a>. '

    },
    {
        title: 'Stallion Stake',
        image : '/stallion.png',
        description: 'On-chain no loss sports fantasy game for ETH Denver Hackathon',
        period: 'March 2024',
        details: 'Developed ERC721 compatible vaults utilizing Euler\'s EVC (Ethereum Vault Connector) architecture. <br>' +
                 'This project showcases the ability to create innovative DeFi solutions that leverage existing protocols and standards.<br>' +
                 '<a href="https://github.com/stallionStake" style="color: #ff00ff; text-decoration: underline;">Github</a>. '

      },
      {
        title: 'Delta Neutral Strategies on Polygon',
        image : '/gammaPdn.png',
        description: 'Yearn V3 Strategies on Polygon for Hackathon',
        period: 'December 2023',
        details: 'Delta Neutral strategies on the Polygon network, utilizing the Yearn V3 architecture. ' +
                 '2nd place in hackathon' + 
                 '<a href="https://github.com/degenRobot/gamma-pdn-strategy-foundry" style="color: #ff00ff; text-decoration: underline;">Github</a>. '
      },
      {
        title: 'Alchemix Transmuter Vaults',
        image : '/alEth.png',
        description: 'Yearn V3 Strategies for Alchemix',
        period: 'Q2 2024',
        details: 'Delta Neutral strategies utilizing the Yearn V3 architecture to assist with repegging of alETH & providing alETH depositing yield via transmuter arbitrage ' +
                 '<a href="https://github.com/degenRobot/alchemix-transmuter" style="color: #ff00ff; text-decoration: underline;">Github</a>. '
      },

  ];
  
  export const skills = [
    'Blockchain Development',
    'DeFi Protocols',
    'Smart Contracts (Solidity, Vyper)',
    'Data Science',
    'Machine Learning',
    'Python',
    'TypeScript',
    'Financial Modeling',
    'Risk Analysis',
    'Project Management'
  ];


  export const links = [
    {
      title: "GitHub",
      description: "",
      url: "https://github.com/degenRobot"
    },
    {
      title: "LinkedIn",
      description: "",
      url: "https://www.linkedin.com/in/matthew-smart-a9226898/"
    },
    {
      title: "Twitter",
      description: "",
      url: "https://twitter.com/degenRobot"
    },
    {
      title: "substack",
      description: " Some old articles : ",
      url: "https://substack.com/@degenrobot"
    },

  ];