import { motion, AnimatePresence } from 'framer-motion';
import React, { useState, useEffect } from 'react';


export const ProjectCard = ({ project, colors }) => {
    const [isExpanded, setIsExpanded] = useState(false);
  
    const toggleExpand = () => setIsExpanded(!isExpanded);
  
    const cardStyle = {
      backgroundColor: colors.secondary,
      borderRadius: '12px',
      overflow: 'hidden',
      boxShadow: `0 4px 6px ${colors.background === '#ffffff' ? 'rgba(0, 0, 0, 0.1)' : 'rgba(255, 255, 255, 0.1)'}`,
      transition: 'transform 0.3s, box-shadow 0.3s',
      display: 'flex',
      flexDirection: 'column',
    };
  
    const imageStyle = {
      width: '100%',
      height: '200px',
      objectFit: 'cover',
    };
  
    const contentStyle = {
      padding: '20px',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    };
  
    const titleStyle = {
      fontSize: '1.2rem',
      fontWeight: '700',
      marginBottom: '10px',
      color: colors.accent,
    };
  
    const expandButtonStyle = {
      backgroundColor: 'transparent',
      border: `2px solid ${colors.accent}`,
      color: colors.accent,
      padding: '5px 15px',
      borderRadius: '20px',
      cursor: 'pointer',
      fontSize: '0.9rem',
      marginTop: 'auto',
      alignSelf: 'flex-start',
      transition: 'background-color 0.3s, color 0.3s',
    };
  
    return (
      <div style={cardStyle}>
        <img src={project.image || '/placeholder.jpg'} alt={project.title} style={imageStyle} />
        <div style={contentStyle}>
          <h3 style={titleStyle}>{project.title}</h3>
          <p style={{fontSize: '1rem', marginBottom: '15px'}}>{project.subtitle}</p>
          <p style={{fontSize: '0.9rem', marginBottom: '15px'}}>{project.description}</p>
          
          <button onClick={toggleExpand} style={expandButtonStyle}>
            {isExpanded ? 'Read Less' : 'Read More'}
          </button>
  
          {isExpanded && (
            <div style={{marginTop: '20px', fontSize: '0.9rem'}} dangerouslySetInnerHTML={{__html: project.details}} />
          )}
        </div>
      </div>
    );
  };



export const LinkCard = ({ link, colors }) => {
  const cardStyle = {
    backgroundColor: colors.secondary,
    borderRadius: '12px',
    padding: '20px',
    boxShadow: `0 4px 6px ${colors.background === '#ffffff' ? 'rgba(0, 0, 0, 0.1)' : 'rgba(255, 255, 255, 0.1)'}`,
    transition: 'transform 0.3s, box-shadow 0.3s',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  };

  const titleStyle = {
    fontSize: '1.2rem',
    fontWeight: '700',
    marginBottom: '10px',
    color: colors.accent,
  };

  const descriptionStyle = {
    fontSize: '0.9rem',
    marginBottom: '15px',
    flex: 1,
  };

  const linkStyle = {
    backgroundColor: colors.primary,
    color: colors.background,
    padding: '10px 15px',
    borderRadius: '25px',
    textDecoration: 'none',
    fontSize: '0.9rem',
    textAlign: 'center',
    transition: 'background-color 0.3s',
    ':hover': {
      backgroundColor: colors.accent,
    },
  };

  return (
    <div style={cardStyle}>
      <h3 style={titleStyle}>{link.title}</h3>
      <p style={descriptionStyle}>{link.description}</p>
      <a href={link.url} target="_blank" rel="noopener noreferrer" style={linkStyle}>
        Visit
      </a>
    </div>
  );
};


export  const ExpandableCard = ({ title, titleUrl, subtitle, period, description, details, colors }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => setIsExpanded(!isExpanded);

  const cardStyle = {
    backgroundColor: colors.secondary,
    padding: '30px',
    marginBottom: '20px',
    borderRadius: '12px',
    boxShadow: `0 4px 6px ${colors.background === '#ffffff' ? 'rgba(0, 0, 0, 0.1)' : 'rgba(255, 255, 255, 0.1)'}`,
    transition: 'transform 0.3s, box-shadow 0.3s',
  };

  const expandButtonStyle = {
    backgroundColor: 'transparent',
    border: `2px solid ${colors.accent}`,
    color: colors.accent,
    padding: '5px 15px',
    borderRadius: '20px',
    cursor: 'pointer',
    fontSize: '0.9rem',
    marginTop: '15px',
    transition: 'background-color 0.3s, color 0.3s',
  };


  
  const titleStyle = {
    fontSize: '1.5rem',
    marginBottom: '10px',
    color: colors.accent,
    textDecoration: 'none',
  };

  return (
    <div style={cardStyle}>
      {titleUrl ? (
        <a href={titleUrl} target="_blank" rel="noopener noreferrer" style={titleStyle}>
          <h3>{title}</h3>
        </a>
      ) : (
        <h3 style={titleStyle}>{title}</h3>
      )}
      <h4 style={{fontSize: '1.2rem', marginBottom: '5px', color: colors.primary}}>{subtitle}</h4>
      {period && <p style={{fontSize: '1rem', marginBottom: '10px', color: colors.text}}>{period}</p>}
      <p style={{fontSize: '1rem', marginBottom: '15px'}}>{description}</p>
      
      <button onClick={toggleExpand} style={expandButtonStyle}>
        {isExpanded ? 'Read Less' : 'Read More'}
      </button>

      {isExpanded && (
        <div style={{marginTop: '20px', fontSize: '1rem'}} dangerouslySetInnerHTML={{__html: details}} />
      )}
    </div>
  );
};

export const ExperienceCard = ({ experience, colors }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const cardStyle = {
    backgroundColor: colors.secondary,
    borderRadius: '8px',
    padding: '20px',
    marginBottom: '20px',
    cursor: 'pointer',
    transition: 'box-shadow 0.3s ease, transform 0.3s ease',
  };

  const titleStyle = {
    fontSize: '1.5rem',
    fontWeight: '600',
    color: colors.primary,
    marginBottom: '5px',
  };

  const companyStyle = {
    fontSize: '1.2rem',
    color: colors.accent,
    marginBottom: '5px',
  };

  const periodStyle = {
    fontSize: '0.9rem',
    color: colors.text,
    marginBottom: '10px',
  };

  const descriptionStyle = {
    fontSize: '1rem',
    color: colors.text,
    marginBottom: '10px',
  };

  const detailsStyle = {
    fontSize: '0.9rem',
    color: colors.text,
  };

  const buttonStyle = {
    backgroundColor: 'transparent',
    border: `2px solid ${colors.accent}`,
    color: colors.accent,
    padding: '5px 15px',
    borderRadius: '20px',
    cursor: 'pointer',
    fontSize: '0.9rem',
    marginTop: '10px',
    transition: 'background-color 0.3s, color 0.3s',
  };

  return (
    <motion.div
      style={cardStyle}
      whileHover={{ boxShadow: `0 5px 15px ${colors.primary}30`, transform: 'translateY(-5px)' }}
      layout
    >
      <motion.h3 style={titleStyle} layout="position">{experience.title}</motion.h3>
      <motion.h4 style={companyStyle} layout="position">{experience.company}</motion.h4>
      <motion.p style={periodStyle} layout="position">{experience.period}</motion.p>
      <motion.p style={descriptionStyle} layout="position">{experience.description}</motion.p>
      
      <AnimatePresence>
        {isExpanded && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
          >
            <div style={detailsStyle} dangerouslySetInnerHTML={{__html: experience.details}} />
          </motion.div>
        )}
      </AnimatePresence>

      <motion.button
        onClick={() => setIsExpanded(!isExpanded)}
        style={buttonStyle}
        whileHover={{ backgroundColor: colors.accent, color: colors.background }}
      >
        {isExpanded ? 'Read Less' : 'Read More'}
      </motion.button>
    </motion.div>
  );
};